import { render, staticRenderFns } from "./QFormDataTable.vue?vue&type=template&id=248f36a2&scoped=true&"
import script from "./QFormDataTable.vue?vue&type=script&lang=js&"
export * from "./QFormDataTable.vue?vue&type=script&lang=js&"
import style0 from "./QFormDataTable.vue?vue&type=style&index=0&id=248f36a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248f36a2",
  null
  
)

export default component.exports