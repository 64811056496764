<template>
    <v-row wrap>
        <v-col cols="12">
            <v-text-field :label="value.FormQuestionConfig.useQuestionAsLabel ? 'Label' : 'Title or Question'" v-model="value.FormQuestionTitle"></v-text-field>
        </v-col>
        <v-col cols="12">
            <text-editor class="summit-email-notification" v-model="value.FormQuestionContent"></text-editor>
        </v-col>
        <v-col cols="12" sm="6">
            <v-checkbox label="Use Question Title as Label" v-model="value.FormQuestionConfig.useQuestionAsLabel"></v-checkbox>
            <v-checkbox label="Format as Currency" v-model="value.FormQuestionConfig.formatAsCurrency"></v-checkbox>
            <v-text-field v-if="!value.FormQuestionConfig.useQuestionAsLabel" label="Field Label" v-model="value.FormQuestionConfig.label"></v-text-field>
            <v-text-field label="Default" v-model="value.FormQuestionConfig.default"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
            <v-select multiple label="Validation Rules" v-model="value.FormQuestionConfig.rules" :items="form_rules_mapped"></v-select>
            <v-text-field label="Hint" v-model="value.FormQuestionConfig.hint"></v-text-field>
        </v-col>
        <v-col cols="6" sm="3">
            <v-select label="Columns" v-model="value.FormQuestionConfig.cols" :items="['',1,2,3,4,5,6,7,8,9,10,11,12]"></v-select>
            <v-select label="Small Columns" v-model="value.FormQuestionConfig.smCols" :items="['',1,2,3,4,5,6,7,8,9,10,11,12]"></v-select>
            <v-select label="Medium Columns" v-model="value.FormQuestionConfig.mdCols" :items="['',1,2,3,4,5,6,7,8,9,10,11,12]"></v-select>
            <v-select label="Large Columns" v-model="value.FormQuestionConfig.lgCols" :items="['',1,2,3,4,5,6,7,8,9,10,11,12]"></v-select>
        </v-col>
        <v-col cols="6" sm="3">
            <v-text-field label="Prefix" v-model="value.FormQuestionConfig.prefix"></v-text-field>
            <v-text-field :append-outer-icon="value.FormQuestionConfig.prependIcon" label="Prepend Icon" v-model="value.FormQuestionConfig.prependIcon"></v-text-field>
            <v-text-field label="Suffix" v-model="value.FormQuestionConfig.suffix"></v-text-field>
            <v-text-field :append-outer-icon="value.FormQuestionConfig.appendIcon" label="Append Icon" v-model="value.FormQuestionConfig.appendIcon"></v-text-field>
            
        </v-col>
        <v-col cols="12" sm="6" class="d-inline-flex align-center" >
            <div ><p class="pt-4">Reference links:</p></div>
            <v-btn small class="ma-2" color="indigo" to="/fontello-demo" target="_blank" text>
                            Quility Icons
                        </v-btn>
                        <v-btn small class="ma-2" color="indigo" @click="loadFontAwesome" text>
                            Font Awesome
                        </v-btn>
        </v-col>
    </v-row>
</template>
<script>
import TextEditor from '@/CMS/editor/components/TextEditor.vue'
export default {
    name: "FmlFieldConfig",
    props: ['value'],
    data: function() {
        return {}
    },
    created: function() {

    },
    components: {
        TextEditor,
    },
    methods: {
        loadFontAwesome: function() {
            window.open("https://fontawesome.com/icons?d=gallery&s=solid&m=free", "_tab")
        },
        loadQuilityIcons: function() {
            window.open("/fontello-demo", "_tab")
        },

    },
    watch: {

    }
}
</script>