<template>
    <v-container fluid class="cms cms-editor grey lighten-5 my-0 px-0  py-0">
        <div class="row pa-5">
            <v-col cols="12" v-if="form">
                <v-row>
                    <v-col cols="12" min-width="100%" class="d-flex">
                        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                        <v-btn icon :disabled="loading" :to="{name:'FML'}">
                            <v-icon>fas fa-th-list</v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn depressed :disabled="loading" color="primary" @click="saveForm()">
                            <v-icon v-if="!hasChanges" small class="pr-2">fas fa-check</v-icon>
                            <span v-if="form.ID == 'new'">Create</span>
                            <span v-else>Save</span>
                        </v-btn>
                        <v-btn depressed class="ml-3" @click="loadFormView" :disabled="form.ID == 'new'">View</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Form Title" v-model="form.FormTitle"></v-text-field>
                    </v-col>
                </v-row>
                <QTabs v-model="currentTab" dark>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Editor</v-tab>
                    <v-tab>Config</v-tab>
                    <v-tab>Response</v-tab>
                    <!--
                    <v-tab>Styles</v-tab>
                    -->
                    <v-tab>Preview</v-tab>
                    <v-tab>Data</v-tab>
                    <v-tab-item>
                        <q-edit-form-sets :form-type="form.FormType" v-model="form.FormQuestionSets" v-on:save="saveForm"></q-edit-form-sets>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="pa-5">
                            <v-col cols="12" sm="6" class="px-6">
                                <v-text-field v-model="form.Slug" label="Slug"></v-text-field>
                                <v-checkbox v-model="form.EnabledInd" label="Enabled"></v-checkbox>
                                <v-checkbox v-model="form.EditableInd" label="Editable"></v-checkbox>
                                <v-checkbox v-model="form.FormConfig.SingleEntry" label="Limit to a single entry"></v-checkbox>
                                <v-checkbox v-if="form.FormConfig.SingleEntry" v-model="form.FormConfig.AgentEditResponse" label="Allow agents to edit entries"></v-checkbox>
                                <date-selector v-model="form.EffDate" label="Effective Date"></date-selector>
                                <date-selector v-model="form.ExpDate" label="Expiration Date"></date-selector>
                                <v-select v-model="form.DivisionID" label="Division" :items="available_divisions"></v-select>
                                <v-select v-model="form.FormConfig.template" label="Template" :items="available_templates"></v-select>
                                <v-select v-model="form.FormType" label="Type" :items="validTypes"></v-select>
                                <v-select v-model="form.FormCategory" label="Category" item-text="dropdown_category_name" item-value="dropdown_category_name" :items="available_categories"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="px-6">
                                <h3>Post-Submission Actions</h3>
                                Actions that will be performed after submitting this form.
                                <v-select v-model="editAction.type" label="Action" :items="available_actions"></v-select>
                                <v-text-field label="Email Address" v-if="(['emailCustom']).indexOf(editAction.type) > -1" v-model="editAction.toEmail"></v-text-field>
                                <div v-if="editAction.type.indexOf('email') > -1">
                                    Email copy preface:
                                    <text-editor class="summit-email-notification" v-model="editAction.emailCopy"></text-editor>
                                </div>
                                <v-textarea v-if="(['customFunction']).indexOf(editAction.type) > -1" v-model="editAction.params" label="JSON Formatted Parameters" messages="This will be passed to the custom function"></v-textarea>
                                <v-combobox multiple chips v-if="(['supportTicket']).indexOf(editAction.type) > -1" label="ZenDesk Tags" v-model="editAction.supportTags"></v-combobox>
                                <v-combobox v-if="(['supportTicket']).indexOf(editAction.type) > -1" v-model="editAction.department" label="Department" item-text="dropdown_category_name" item-value="dropdown_category_name" :items="available_categories"></v-combobox>
                                <v-btn color="primary" @click="saveAction">Add</v-btn>
                                <v-btn @click="resetAction">Reset</v-btn>
                                <v-card two-line v-if="form.FormConfig.postActions">
                                    <v-list-item two-line v-for="(action, key) in form.FormConfig.postActions" :key="key" cols="12">
                                        <v-list-item-icon>
                                            <v-btn @click="editAnAction(key)" color="primary" icon>
                                                <v-icon>fas fa-edit</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-icon>
                                            <v-btn @click="deleteAction(key)" icon color="red">
                                                <v-icon>fas fa-trash</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{action.type}} - {{action.toEmail}} <span v-if="action.supportTags">{{action.supportTags.join(",")}}</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <div v-html="action.emailCopy"></div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                <div class="mt-12" v-if="isQuiz">
                                    <v-row class="d-flex px-3">
                                        <h3>Quiz Config</h3>
                                    </v-row>
                                    <v-row class="d-flex align-center px-3">
                                        <div class="pr-5">Passing Score Percent</div>
                                        <v-text-field style="max-width: 200px;" type="number" min="0" max="100" label="example: 75" v-model="form.FormConfig.passingScore" :rules="[form_rules.required]" />
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="pa-5">
                            <h3 class="px-5">Form Response</h3>
                        </v-row>
                        <v-row class="pa-5">
                            <v-col cols="12" sm="6" class="px-6">
                                <p class="mb-0">Message after form submission.</p>
                                <text-editor class="summit-email-notification" v-model="form.FormConfig.response"></text-editor>
                            </v-col>
                            <v-col cols="12" sm="6" class="px-6">
                                <p class="mb-1">Passing score message</p>
                                <text-editor label="Passing Response" v-model="form.FormConfig.passResponse" :rules="[form_rules.required]" />
                                <p class="pt-12 mb-1">Failing score message</p>
                                <text-editor label="Failing Response" v-model="form.FormConfig.failResponse" :rules="[form_rules.required]" />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <form-view :form="form"></form-view>
                    </v-tab-item>
                </QTabs>
                <v-expansion-panels class="mt-8">
                    <v-expansion-panel>
                        <v-expansion-panel-header>Debug</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <pre>{{ JSON.stringify(form, null, 2) }}</pre>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
        </div>
        <v-snackbar v-model="notice.visible" top :color="notice.color" multi-line :timeout="6000">
            {{ notice.text }}
            <v-btn text @click="notice.visible = false">
                Close
            </v-btn>
        </v-snackbar>
        <v-dialog v-model="show_media" width="75%">
            <media-library v-on:usefile="useMediaFile"></media-library>
        </v-dialog>
        <v-dialog v-model="show_documents" width="75%">
            <document-library :is-popup="true" :just-link="true" v-on:usefile="useDocumentFile"></document-library>
        </v-dialog>
    </v-container>
</template>
<script>
import draggable from "vuedraggable";
import FormView from '@/FML/views/index.vue'
import Category from '@/store/Models/Category'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import slugify from 'slugify'
import { EventBus } from '@/event-bus';
import MediaLibrary from '@/CMS/editor/mediaLibrary.vue'
import DocumentLibrary from '@/CMS/editor/documentLibrary.vue'
import Styles from '@/CMS/editor/components/Styles'
import ImageSelector from '@/CMS/editor/components/ImageSelector.vue'
import DateSelector from '@/CMS/editor/components/DateSelector.vue'
import QTabs from '../../components/navigation/QTabs.vue'
import QEditFormSets from './QEditFormSets.vue'
import TextEditor from "@/CMS/editor/components/TextEditor";

export default {
    data() {
        return {
            mediaCallback: function() {},
            show_media: false, //shows the media library
            show_documents: false, // shows the document library
            counter: Math.floor(Math.random() * 1000000000),
            hasChanges: false,
            editAction: {
                ix: -1,
                type: '',
                toEmail: null,
                supportTags: [],
                params: null,
                emailCopy: null
            },
            currentTab: 0,
            available_templates: [{
                text: 'Default Template',
                value: 'default'
            }],
            validTypes: [
                'Simple',
                'Quiz',
            ],
            available_actions: [{
                    text: "Email Agent the data.",
                    value: 'emailAgent'
                },
                {
                    text: "Email Agent's Direct Upline the data.",
                    value: 'emailAgentUpline'
                },
                {
                    text: "Email Agent's AO the data.",
                    value: 'emailAgentAo'
                },
                {
                    text: "Email Agent's SFG Direct the data.",
                    value: 'emailAgentDirect'
                },
                {
                    text: "Send data to Custom Email Address",
                    value: 'emailCustom'
                },
                {
                    text: "Create Support Ticket",
                    value: 'supportTicket'
                },
                {
                    text: "Custom Function",
                    value: 'customFunction'
                },
            ],
            collapseBlocks: false,
            loading: false,
            form: null,
            notice: {},
        };
    },
    mounted: function() {
        if (this.$route.params.form_id) {
            this.loadForm(this.$route.params.form_id);
        } else if (this.$route.params.copy_form) {
            this.loadForm('new')
        } else {
            this.loadForm('new')
        }

        var g = this
        EventBus.$on('medialibrary', function(callback) {
            g.show_media = true;
            g.mediaCallback = callback;
        });
        EventBus.$on('documentlibrary', function(callback) {
            g.show_documents = true;
            g.documentCallback = callback;
        });
    },
    beforeActivated() {

    },
    computed: {
        available_divisions() {
            var d = this.raw_divisions;
            d.push('All');
            return d;
        },
        available_categories() {
            return Category.query().orderBy('category', 'asc').get();
        },
        'categories': function() {
            return Category.query().where('zendesk_category_id', (value) => (value != 0 && value != null)).get();
        },
        'departments': function() {
            var cats = [...this.categories];
            cats.push({
                'category': 'Quility U',
                'dropdown_category_name': 'Quility U',
            })
            cats.push({
                'category': 'Quility Tech',
                'dropdown_category_name': 'Quility Tech',
            })
            cats.push({
                'category': 'Events',
                'dropdown_category_name': 'Events',
            })
            cats.push({
                'category': 'Business Intelligence',
                'dropdown_category_name': 'Business Intelligence',
            })
            cats.push({
                'category': 'Quility HQ Help',
                'dropdown_category_name': 'Quility HQ Help',
            })
            cats.push({
                'category': 'Bug',
                'dropdown_category_name': 'Bug',
            })
            cats.push({
                'category': 'Feature Request',
                'dropdown_category_name': 'Feature Request',
            })
            return cats.sort(function(a, b) {
                if (a.category.toUpperCase() < b.category.toUpperCase()) {
                    return -1;
                }
                if (a.category.toUpperCase() > b.category.toUpperCase()) {
                    return 1;
                }
                return 0;
            })
        },
        isQuiz() {
            // update when field is added to db
            return this.form.FormType === 'Quiz';
        },
        computed_form_type() {
            return this.form?.FormType ?? this.$route.params.form_type
        }
    },
    methods: {
        saveForm: function(close) {
            var g = this;
            //set the Sort Order for Question Sets and Questions.
            this.form.FormQuestionSets.forEach(function(set, ix) {
                g.form.FormQuestionSets[ix].SortOrder = ix;
                g.form.FormQuestionSets[ix].FormQuestions.forEach(function(question, qix) {
                    g.form.FormQuestionSets[ix].FormQuestions[qix].SortOrder = qix;
                })
            })
            let saved_form = Object.assign({}, this.form);
            g.$set(g, "form", null);
            g.loading = true;
            if (saved_form.ID == "new") {
                if (saved_form.FormTitle == 'The Title') {
                    g.loading = false
                    g.$set(g, "form", saved_form);
                    g.$set(g, 'notice', {
                        text: "Please enter a real title for the form",
                        visible: true,
                        color: 'error'
                    })
                    return
                }
                QuilityAPI.createForm(saved_form).then(function(json) {
                    g.loading = false;
                    if (json.error) {
                        g.$set(g, "form", saved_form);
                        g.$set(g, 'notice', json)
                        return;
                    }
                    g.$set(g, "form", json);
                    g.hasChanges = false;
                }).catch(function(err) {
                    g.$set(g, 'notice', err)
                })
            } else {
                QuilityAPI.updateForm(saved_form).then(function(json) {
                    g.loading = false;
                    if (json.error) {
                        g.$set(g, "form", saved_form);
                        g.$set(g, 'notice', json)
                        return;
                    }
                    g.$set(g, "form", json);
                    g.hasChanges = false;
                }).catch(function(err) {
                    g.$set(g, 'notice', err)
                })
            }
        },
        loadForm: function(id) {
            var g = this
            g.$set(g, "form", null);
            QuilityAPI.getForm(id, this.computed_form_type)
                .then(function(data) {
                    g.$set(g, "form", data);
                    if (id == 'new') {
                        g.setupPresets();
                    }
                    g.$forceUpdate();
                })
                .catch(function(err) {
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        setupPresets: function() {
            this.currentTab = 0
            if (this.$route.params.form_type) {
                this.form.FormType = this.$route.params.form_type
            }
            if (this.$route.params.copy_form) {
                this.form = { ...this.$route.params.copy_form }
            }
        },
        loadFormView() {
            if (this.form.FormType === "Quiz") {
                this.$router.push('/quiz/' + this.form.Slug);
            } else {
                this.$router.push('/form/' + this.form.Slug);
            }

        },
        useMediaFile: function(file) {
            this.mediaCallback(file);
            this.show_media = false;
        },
        useDocumentFile: function(file) {
            this.documentCallback(file.url, file.title);
            this.show_documents = false;
        },
        saveAction: function() {
            if (typeof this.form.FormConfig.postActions == 'undefined') {
                this.$set(this.form.FormConfig, "postActions", []);
            }
            if (this.editAction.ix == -1) {
                this.form.FormConfig.postActions.push(this.editAction);
            } else {
                this.form.FormConfig.postActions[this.editAction.ix] = { ...this.editAction }
            }
            this.resetAction();
        },
        resetAction: function() {
            this.editAction = {
                ix: -1,
                type: '',
                toEmail: null,
                supportTags: [],
                params: null,
                emailCopy: null
            }
        },
        editAnAction: function(ix) {
            this.editAction = { ...this.form.FormConfig.postActions[ix] };
            this.editAction.ix = ix;
        },
        deleteAction: function(ix) {
            this.form.FormConfig.postActions.splice(ix, 1);
        },
    },
    watch: {
        '$route.params.form_id': function(newV, oldV) {
            var g = this
            if (typeof newV != 'undefined') {
                this.loading = false;
                this.$nextTick(g.loadForm(newV));
            } else {
                this.$nextTick(g.loadForm('new'));
            }
        },
        'form.FormTitle': function(newV) {
            if (typeof newV != 'undefined' && typeof this.form != 'undefined' && this.form.ID == "new") {
                this.form.Slug = slugify(newV, { lower: true, strict: true });
            }
        },
        'form.ID': function(newV, oldV) {
            if (typeof newV != 'undefined' && newV != null && oldV == null && typeof oldV != 'undefined') {
                this.loading = false;
                this.$router.push('/cms/editor/' + newV)
            }
        },
        'form.FormQuestionSets': {
            deep: true,
            handler: function(newV, oldV) {
                this.hasChanges = true;
            }
        }
    },
    components: {
        FormView,
        MediaLibrary,
        ImageSelector,
        DateSelector,
        DocumentLibrary,
        QTabs,
        QEditFormSets,
        TextEditor
    },
}

</script>
<style scoped>
.block {
    position: relative;
}

.fab-container {
    padding: 15px;
    background-color: #f7f7f7;
    padding-right: 60px;
}

.unpublished-block {
    color: rgba(0, 0, 0, 0.1) !important;
}

.fab-container.collapsed {
    position: relative;
}

.block-container.collapsed {
    display: none;
}

</style>
