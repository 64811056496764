<template>
    <v-container fluid class="cms cms-editor grey lighten-5 my-0 px-0  py-0">
        <div class="row pa-5">
            <v-col cols="12" v-if="page">
                <v-row>
                    <v-col cols="12" min-width="100%" class="d-flex">
                        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                        <v-btn icon :disabled="loading" :to="{name:'CMS'}">
                            <v-icon>fas fa-th-list</v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn depressed :disabled="loading" color="primary" @click="savePage()">
                            <v-icon v-if="!hasChanges" small class="pr-2">fas fa-check</v-icon>
                            <span v-if="page.id == null">Create</span>
                            <span v-else>Save</span>
                        </v-btn>
                        <v-btn depressed class="ml-3" @click="loadPageView" :disabled="page.id == null">View</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Page Title" v-model="page.title"></v-text-field>
                    </v-col>
                </v-row>
                <QTabs v-model="currentTab" dark>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Editor</v-tab>
                    <v-tab>Meta</v-tab>
                    <!--
                    <v-tab>Styles</v-tab>
                    -->
                    <v-tab>Preview</v-tab>
                    <v-btn class="mt-6" elevation="0" fab absolute top right @click="toggleCollapseBlocks()">
                        <v-icon small>fas {{collapseBlocksIcon()}}</v-icon>
                    </v-btn>
                    <v-tab-item>
                        <q-edit-page-blocks v-model="page.blocks"></q-edit-page-blocks>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="pa-5">
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="page.slug" label="Slug"></v-text-field>
                                <v-select v-model="page.status" label="Status" :items="available_statuses" item-text="label" item-value="key"></v-select>
                                <v-text-field v-model="page.order" label="Sort Order"></v-text-field>
                                <date-selector v-model="page.created_at" label="Publish Date"></date-selector>
                                <v-textarea v-model="page.description" label="Description"></v-textarea>
                                <image-selector v-model="page.image" label="Image"></image-selector>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select v-model="page.division" label="Division" :items="available_divisions"></v-select>
                                <v-select v-model="page.template" label="Template" :items="available_templates"></v-select>
                                <v-select v-model="page.category_id" label="Category" item-text="dropdown_category_name" item-value="id" :items="available_categories"></v-select>
                                <v-text-field v-if="page.template == 'external' || page.category_id == 14" v-model="page.external_url" label="External Resource URL" messages="If this is an internal support resource enter 'internal' to view using the slug."></v-text-field>
                                <!-- Event and Contests only //-->
                                <div v-if=" page.category_id==14">
                                    <date-selector v-model="page.startDate" label="Start Date">
                                    </date-selector>
                                    <date-selector v-model="page.endDate" label="End Date"></date-selector>
                                    <v-text-field v-model="page.sponsor" label="Sponsor"></v-text-field>
                                </div>
                                <!-- <v-autocomplete v-if="page.template == 'carrier'" label="Carrier" item-value="ID" item-text="CarrierName" v-model="page.CarrierID" :items="carriers" :rules="[form_rules.required]"></v-autocomplete> -->
                                <q-autocomplete-carriers v-if="page.template == 'carrier'" v-model="page.carrier_id" @update="updateCarrier($event)"></q-autocomplete-carriers>
                                <v-img v-if="!loadingCarrier && page.template == 'carrier' && CarrierLogoURL != false && CarrierLogoURL !== null" :src="CarrierLogoURL" style="border: 1px solid #ccc;" />
                                <v-progress-linear indeterminate v-if="loadingCarrier"></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <page-view :page="page"></page-view>
                    </v-tab-item>
                </QTabs>
                <v-expansion-panels class="mt-8">
                    <v-expansion-panel>
                        <v-expansion-panel-header>Debug</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <pre>{{JSON.stringify($store.state.entities.page.notice, null, 2)}}</pre>
                            <pre>{{ JSON.stringify(page, null, 2) }}</pre>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
        </div>
        <v-snackbar v-model="notice.visible" top :color="notice.color" multi-line :timeout="6000">
            {{ notice.text }}
            <v-btn text @click="notice.visible = false">
                Close
            </v-btn>
        </v-snackbar>
        <v-dialog v-model="show_media" width="75%">
            <media-library v-on:usefile="useMediaFile"></media-library>
        </v-dialog>
        <v-dialog v-model="show_documents" width="75%">
            <document-library :is-popup="true" :just-link="true" v-on:usefile="useDocumentFile"></document-library>
        </v-dialog>
        <v-dialog v-if="page" v-model="showDialogConfirmDelete" width="500">
            <v-card>
                <v-card-title class="headline error" color="error">
                    <h4 class="q_white-text">Move "{{page.title}}" to the Trash?</h4>
                </v-card-title>
                <v-card-text class="pt-3">
                    <p><strong>Are you sure you want to remove this content?</strong></p>
                    <p>You are about to move "{{page.title}}" into the trash. This will unpublish the page. Any links to this page will no longer work.</p>
                    <p class="caption">*You can use "FILTER PAGES", STATUS / Trash to change the status back to Published or Draft in the Meta tab.</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="error" outlined @click="confirmSaveToTrash()">
                        Move to Trash
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" depressed @click="showDialogConfirmDelete = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import draggable from "vuedraggable";
import PageView from '@/CMS/views/index.vue'
import QAutocompleteCarriers from '@/components/utils/QAutocompleteCarriers.vue'
import Category from '@/store/Models/Category'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Page from '@/store/Models/Page'
import slugify from 'slugify'
import { EventBus } from '@/event-bus';
import MediaLibrary from './mediaLibrary.vue'
import DocumentLibrary from './documentLibrary.vue'
import Styles from './components/Styles'
import ImageSelector from './components/ImageSelector.vue'
import DateSelector from './components/DateSelector.vue'
import QTabs from '../../components/navigation/QTabs.vue'

import moment from 'moment'
export default {
    data() {
        return {
            mediaCallback: function() {},
            show_media: false, //shows the media library
            show_documents: false, // shows the document library
            dragging: false,
            counter: Math.floor(Math.random() * 1000000000),
            page_id: null,
            hasChanges: false,
            original_status: null,
            currentTab: 0,
            available_types: [{
                label: "Accordion",
                value: "accordion"
            }, {
                label: "Banner",
                value: "banner"
            }, {
                label: "Leaderboard",
                value: "leaderboard"
            }, {
                label: "Data Table",
                value: "data-table-group"
            }, {
                label: "Full Width Text Block",
                value: "full-width-text"
            }, {
                label: "Two Columns 50/50",
                value: "two-col-5050"
            }, {
                label: "Two Columns 25/75",
                value: "two-col-2575"
            }, {
                label: "Two Columns 75/25",
                value: "two-col-7525"
            }, {
                label: "Three Columns 33/33/33",
                value: "three-col-333333"
            }, {
                label: "Three Columns 25/50/25",
                value: "three-col-255025"
            }, {
                label: "Four Columns 25/25/25/25",
                value: "four-col-25252525"
            }, {
                label: "Card Group",
                value: "card-group"
            }, {
                label: "Wistia Video Group",
                value: "wistia-video-group"
            }, {
                label: "Iframe Group",
                value: "iframe-group"
            }, {
                label: "Script/Embed Group",
                value: "script-embed-group"
            }, {
                label: "Button Group",
                value: "button-group"
            }, {
                label: "Call to Action",
                value: "call-to-action"
            }, {
                label: "Spacer",
                value: "spacer"
            }, {
                label: "Carrier Contact Info",
                value: "carrier-contact-info"
            }, {
                label: "Carrier Products",
                value: "carrier-products"
            }, {
                label: "Acknowledge Checkbox",
                value: "acknowledge-check-box"
            }, {
                label: 'Summit Calculator',
                value: 'summit-calculator',
            }],
            available_templates: [{
                text: 'Default Template',
                value: 'default'
            }, {
                text: 'Resources',
                value: 'resources'
            }, {
                text: 'Training',
                value: 'training'
            }, {
                text: 'News',
                value: 'news'
            }, {
                text: 'Contest',
                value: 'contests'
            }, {
                text: 'Events',
                value: 'events'
            }, {
                text: 'Marketing',
                value: 'marketing'
            }, {
                text: 'Quility Tech',
                value: 'quility-tech'
            }, {
                text: 'Agency Building',
                value: 'agency-building'
            }, {
                text: 'External Resource',
                value: 'external'
            }, {
                text: 'Carrier Detail Page',
                value: 'carrier'
            }, {
                text: 'Carrier Resources',
                value: 'carrier-resources'
            }, {
                text: 'Benefits',
                value: 'benefits'
            }, ],
            available_styles: Styles.BlockStyles,
            available_page_styles: Styles.PageStyles,
            collapseBlocks: false,
            showDialogConfirmDelete: false,
            carriers: [],
            carrier: {},
            CarrierLogoURL: false,
            loadingCarrier: false
        };
    },
    mounted: function() {
        if (this.$route.params.page_id) {
            this.loadPage(this.$route.params.page_id);
        }

        if (this.$route.params.copy_page) {
            this.loadPage('new')
        }

        var g = this
        EventBus.$on('medialibrary', function(callback) {
            g.show_media = true;
            g.mediaCallback = callback;
        });
        EventBus.$on('documentlibrary', function(callback) {
            g.show_documents = true;
            g.documentCallback = callback;
        });
    },
    beforeActivated() {

    },
    computed: {
        available_statuses() {
            return [
                { key: 'publish', label: 'Publish' },
                { key: 'draft', label: 'Draft' },
                { key: 'trash', label: 'Trash' }
            ]
        },
        available_divisions() {
            var d = this.raw_divisions;
            d.push('All');
            return d;
        },
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        },
        available_categories() {
            return Category.query().orderBy('category', 'asc').get();
        },
        notice() {
            return this.$store.state.entities.page.notice;
        },
        loading() {
            return this.$store.state.entities.page.loading;
        },
        page() {
            return this.$store.state.entities.page.currentPage;
        },
        division_items: function() {
            return [
                'All',
                ...this.raw_divisions,
            ]
        },
        role_items: function() {
            return [
                'Any',
                ...this.role_choices,
            ]
        },
        permission_items: function() {
            return [
                'Any',
                ...this.permission_choices,
            ]
        },
    },
    methods: {
        updateCarrier: function(event) {
            this.page.carrier_id = event

            if (!event) {
                this.carrier = false
                this.CarrierLogoURL = false
                return
            }

            //get carrier image
            let g = this
            g.loadingCarrier = true
            QuilityAPI.getCarrier(event, true).then(function(json) {
                g.carrier = json.data
                g.CarrierLogoURL = json.data.CarrierLogoURL
                g.loadingCarrier = false
            })

            //update all blocks with the carrier id
            this.page.blocks.map((block, index) => {
                this.$set(this.page.blocks[index], 'CarrierID', event)
                this.$set(this.page.blocks[index], 'CarrierIDs', [event])
                this.$set(this.page.blocks[index], 'key', Math.random())
            })
        },
        // removeAt(idx) {
        //     this.page.blocks.splice(idx, 1);
        // },
        // addAt(idx) {
        //     this.counter++;
        //     this.page.blocks.splice(idx, 0, {
        //         id: this.counter,
        //         type: 'full-width-text',
        //         text: 'Sed nec diam non eros lobortis tempor et eget sapien. Sed rutrum orci eget ligula mattis, vel vestibulum ligula feugiat. Cras et velit ut ipsum fringilla vulputate.',
        //         hasFocus: false,
        //         styles: [],
        //         permissions: { role: null, permission: null, division: null },
        //         CarrierID: this.page.carrier_id,
        //         CarrierIDs: [this.page.carrier_id],
        //         key: Math.random()
        //     });
        // },
        // setFocus: function(idx) {
        //     for (var i = 0; i < this.page.blocks.length; i++) {
        //         if (idx != i) {
        //             this.page.blocks.hasFocus = false;
        //         }
        //     }
        // },
        // changeBlockType: function(idx, type) {
        //     this.page.blocks[idx].type = type;
        //     if (typeof this.page.blocks[idx].styles == 'undefined') {
        //         this.page.blocks[idx].styles = [];
        //     }
        // },
        confirmSaveToTrash() {
            this.original_status = 'trash'
            this.showDialogConfirmDelete = false

            var g = this
            QuilityAPI.updatePage(this.page).then(function() {
                g.hasChanges = false;
                g.original_status = g.page.status;
                // g.$router.push("/cms")
                g.$router.push({ name: 'CMS', params: { 'refresh': true } })
            })
        },
        savePage: function(close) {
            var g = this;
            if (this.page.id == null) {
                if (this.page.title == 'The Title') {
                    Page.commit((state) => {
                        state.loading = false
                        state.notice = {
                            text: "Please enter a real title for the page",
                            visible: true,
                            color: 'error'
                        }
                    })
                    return
                }
                QuilityAPI.createPage(this.page).then(function() {
                    g.hasChanges = false;
                })
            } else if (g.original_status !== 'trash' && this.page.status === 'trash') {
                // status changed to trash
                g.showDialogConfirmDelete = true
            } else {
                QuilityAPI.updatePage(this.page).then(function() {
                    g.hasChanges = false;
                    g.original_status = g.page.status;
                })
            }
        },
        loadPage: function(id) {
            var g = this
            QuilityAPI.getPage(id)
                .then(function() {
                    if (id == 'new') {
                        g.setupPresets();
                    }
                    g.$forceUpdate();
                    g.original_status = g.page.status;
                })
                .catch(function(err) {
                    g.showError("Whoops! Can't find that page!")
                    console.error(err)
                });
            g.hasChanges = false;
        },
        setupPresets: function() {
            this.currentTab = 0
            if (this.$route.params.category_id) {
                this.$store.state.entities.page.currentPage.category_id = this.$route.params.category_id
            }

            if (this.$route.params.copy_page) {
                this.$store.state.entities.page.currentPage = this.$route.params.copy_page
            }

            if (this.$route.params.page_type) {
                switch (this.$route.params.page_type) {
                    case 'contest':
                        this.$store.state.entities.page.currentPage.category_id = 14
                        this.$store.state.entities.page.currentPage.template = 'contest'
                        this.currentTab = 1
                        break;
                    case 'news':
                        this.$store.state.entities.page.currentPage.category_id = 15
                        this.$store.state.entities.page.currentPage.template = 'news'
                        this.currentTab = 1
                        break;
                    case 'support-resource':
                        this.$store.state.entities.page.currentPage.template = 'external'
                        this.$store.state.entities.page.currentPage.category_id = 9
                        this.currentTab = 1
                        break;
                }
            }
        },
        loadPageView() {
            this.$router.push('/page/' + this.page.slug);
        },
        useMediaFile: function(file) {
            this.mediaCallback(file);
            this.show_media = false;
        },
        useDocumentFile: function(file) {
            this.documentCallback(file.url, file.title);
            this.show_documents = false;
        },
        collapsedClasses: function(classes) {
            if (this.collapseBlocks)
                classes.push('collapsed')
            return classes.join(' ')
        },
        collapseBlocksIcon: function() {
            if (this.collapseBlocks)
                return "fa-chevron-left"
            return "fa-chevron-down"
        },
        getBlockTypeLabel(block) {
            return this.titleCase(block.type.replace('-', ' '));
        },
        formatLabel: function(key) {
            var str = key.replace("_", " ");
            str = str.replace("-", " ");
            return this.titleCase(str);
        },
        toggleBlockVisible: function(idx) {
            this.page.blocks[idx].status = this.page.blocks[idx].status !== 'draft' ? 'draft' : 'publish'
        },
        publishingDates: function(element) {
            var dates = ""

            if (element.startDate !== undefined && element.startDate !== "" && element.startDate !== null)
                dates += moment(element.startDate).format("l")

            dates += " - "

            if (element.endDate !== undefined && element.endDate !== "" && element.endDate !== null)
                dates += moment(element.endDate).format("l")

            if (dates !== " - ")
                return '<span class="ml-2">' + dates + '</span>'

            return ''
        },
        publishStatusClass(status) {
            return status === 'draft' ? 'unpublished-block' : ''
        },
        toggleCollapseBlocks: function() {
            this.collapseBlocks = !this.collapseBlocks
        },
        blockIsHidden(block) {
            if (typeof block.permissions.division == 'undefined') {
                return false
            }
            if (block.permissions.division == 'All' || block.permissions.division == null) {
                return false
            }
            if (block.permissions.division == this.user.currentSetDivision) {
                return false
            }
            return true
        }
    },
    watch: {
        '$route.params.page_id': function(newV, oldV) {
            var g = this
            if (typeof newV != 'undefined') {
                this.carrier = {}
                this.CarrierLogoURL = false
                this.$nextTick(g.loadPage(newV));
            }
        },
        'page.title': function(newV) {
            if (typeof newV != 'undefined' && typeof this.page != 'undefined' && this.page.id == null) {
                this.page.slug = slugify(newV, { lower: true, strict: true });
            }
        },
        'page.id': function(newV, oldV) {
            if (typeof newV != 'undefined' && newV != null && oldV == null && typeof oldV != 'undefined') {
                this.$router.push('/cms/editor/' + newV)
            }
        },
        'page.blocks': {
            deep: true,
            handler: function(newV, oldV) {
                this.hasChanges = true;
            }
        }
    },
    components: {
        draggable,
        PageView,
        MediaLibrary,
        ImageSelector,
        DateSelector,
        DocumentLibrary,
        QAutocompleteCarriers,
        QTabs,
    },
}
</script>
<style scoped>
.block {
    position: relative;
}

.fab-container {
    padding: 15px;
    background-color: #f7f7f7;
    padding-right: 60px;
}

.unpublished-block {
    color: rgba(0, 0, 0, 0.1) !important;
}

.fab-container.collapsed {
    position: relative;
}

.block-container.collapsed {
    display: none;
}
</style>