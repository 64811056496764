<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-row class="mx-5">
            <v-col cols="12">
            <q-categories-data-table></q-categories-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QCategoriesDataTable from '@/components/datatables/QCategoriesDataTable.vue'

export default {
    props: ['isPopup', 'justLink'],
    name: 'categoriesView',
    data() {
        return {
            loading: false,
            // categoriesList: [],
            search: null,
            rows: 100,
            CategoryName: null,
        }
    },
    mounted: function() {
    },
    computed: {
    },
    methods: {
    },
    watch: {
    },
    components: {
        QCategoriesDataTable
    }
}
</script>
