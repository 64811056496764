<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <div class="full-width">
                            <p class="q-display-3 pl-7 pt-2 pb-3 mt-0 mb-0 white--text">CMS Categories
                                <v-btn icon @click="resetData" dark>
                                    <v-icon small>fas fa-sync-alt</v-icon>
                                </v-btn>
                            </p>
                            <template>
                                <div class="pb-3 ml-7 mb-5">
                                    <v-btn color="q_leads_2" dark @click="addNewCategory">
                                        <v-icon small left>fas fa-plus</v-icon> New Category
                                    </v-btn>
                                </div>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page="100" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 clickable pointer" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options"  @click:row="editCategory" :must-sort="true">
                <template v-slot:item.$id="{ item }">
                    <span class="nowrap">
                        <v-btn text small class="q_new_b_1--text  body-2" @click="editCategory(item)">
                            <v-icon small>fas fa-pencil-alt</v-icon>
                        </v-btn>
                        <v-btn v-if="item.status !== 'trash'" text small class="red--text  body-2" @click.stop="confirmDeleteCategory(item)">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>
                        <v-btn v-else text small class="red--text  body-2" @click.stop="confirmDeleteCategory(item)">
                            <v-icon small>fas fa-minus-circle</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:item.zendesk_category_id="{ item }">
                    <v-icon small v-if="item.zendesk_category_id >= 1">fas fa-check</v-icon>
                    
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{formatDate(item.created_at)}}
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan=10>
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <!-- Update category -->
        <v-dialog v-model="updateCategoryDialog" width="50%">
            <v-card>
                <v-card-title>{{ updateCategoryTitle }}</v-card-title>
                <v-card-text v-if="updateCategoryDialog">
                    <v-form v-model="valid" ref="form" :disabled="saving">
                        <v-col cols="12" v-if="error">
                        <v-alert type="error">
                            {{ error }}
                        </v-alert>
                        </v-col>
                        <v-col cols="12" v-if="warning">
                            <v-alert type="warning">
                                {{ warning }}
                            </v-alert>
                        </v-col>
                        <v-row wrap>
                            <v-col cols="12">
                                <v-text-field v-model="currentCategory.category" label="Title" :rules="[form_rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="currentCategory.description" label="Description"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox v-model="currentCategory.zendesk_category_id" label="Support Category?" :false-value="0" :true-value="1"></v-checkbox>
                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-5 my-5" color="primary" @click="saveCategory">Save Changes</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5 my-5" color="error" depressed @click="updateCategoryDialog = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirmDelete" width="500">
            <v-card>
                <v-card-title class="headline error" color="error">
                    <h4 class="q_white-text">Move <span v-if="deleteCategoryPermanently()">(PERMANENT)</span>"{{deleteCategoryItemTitle}}" to the trash? </h4>
                </v-card-title>
                <v-card-text class="pt-3">
                    <p class="body">Are you sure you want to <strong v-if="deleteCategoryPermanently()">PERMANENTLY</strong> remove this content? This will delete the category</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="ml-5" color="error" outlined @click="deleteCategory()">
                        Delete
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5" color="error" depressed @click="dialogConfirmDelete = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "@/components/datatables/QDataTableMixin"
import Category from '@/store/Models/Category'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QCategoryDataTable",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'category',
            sync_dialog: true,
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            options: { sortBy: ['created_at'], sortDesc: [true] },
            category_id: null,
            updateCategoryDialog: false,
            updateCategoryTitle: '',
            currentCategory: {},
            dialogConfirmDelete: false,
            deleteCategoryItem: null,
            deleteCategoryItemTitle: "",
            warning: null,
            error: null,
            valid: false,
            saving: false,

        }
    },
    updated() {
        this.$nextTick(function() {
            if (this.$route.params.refresh) {
                this.$route.params.refresh = false
                this.refreshData(this.search)
            }
        })
    },
    computed: {
        all_data: function() {
            return Category.query().orderBy(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc').get();
        },
        category() {
            return this.$store.state.entities.category.currentCategory;
        },
        the_headers: function() {
            return [{
                    text: 'Title',
                    value: 'category',
                    align: 'start',
                    sortable: true,
                    class: 'header-class',
                },
                {
                    text: 'Description',
                    value: 'description',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Support',
                    value: 'zendesk_category_id',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Action',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            Category.create({ data: [] })
            QuilityAPI.getCategories(this.filters, opt);
            this.firstPull = false;
        }, 200),
        addNewCategory: function() {
            let g = this;
            g.updateCategoryTitle = 'Add new category';
            g.currentCategory.id = null;
            g.currentCategory.category = '';
            g.currentCategory.description = '';
            g.currentCategory.zendesk_category_id = 0;
            g.updateCategoryDialog = true;
        },
        showSyncDialog: function() {
            this.sync_dialog = true;
            let g = this;
            setTimeout(function() { g.sync_dialog = false }, 3000);
        },
        saveCategory: function(close) {
            let g = this;
            let name = g.currentCategory.category
            if (!this.$refs.form.validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 5000)
                this.saving = false;
                return false;
            }
            if (typeof g.currentCategory.id == 'undefined' || g.currentCategory.id == null) {
                QuilityAPI.createCategory(this.currentCategory).then(function() {
                    g.hasChanges = false;
                    g.showInfo("'" + name + "' has been created. ")
                })
                g.updateCategoryDialog = false
            } else {
                QuilityAPI.saveCategory(g.currentCategory).then(function() {
                    g.hasChanges = false;
                    g.showInfo("'" + name + "' has been updated. ")
                }).catch(function(err) {
                    g.showError("Error: " + err);
                    g.hasChanges = false;
                })
                g.updateCategoryDialog = false
            }
        },
        editCategory: function(category) {
            let g = this;
            g.updateCategoryTitle = 'Edit category';
            g.$set(this, 'currentCategory', category);
            g.updateCategoryDialog = true;
        },
        confirmDeleteCategory: function(item) {
            this.deleteCategoryItem = item
            this.deleteCategoryItemTitle = item.category
            this.dialogConfirmDelete = true
        },
        deleteCategory: function() {
            let g = this
            let name = this.deleteCategoryItem.category
            QuilityAPI.deleteCategory(this.deleteCategoryItem.id).then(function() {
                g.refreshData();
                g.showInfo("'" + name + "' has been deleted. ")
            });
            this.dialogConfirmDelete = false
        },
        deleteCategoryPermanently: function() {
            if (this.deleteCategoryItem == null)
                return false
            return this.deleteCategoryItem.status == 'trash'
        },

        resetData() {
            this.search = null
            this.filters.exclude_zendesk_support = true
            this.filters.status = "-All-"
            this.refreshData(this.search)
        }
    },
    watch: {

    },
    components: { 
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
